.aboutus-bg-cover {
	z-index: 0;
	position: relative;
	overflow: hidden;
}
.aboutus-bg-cover::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	/* background-color: rgba(255, 255, 255, 0.825); */
	background-color: rgba(23, 23, 46, 0.6);
	top: 0;
	left: 0;
	z-index: -1;
}
.aboutus-bg-cover::before {
	z-index: -1;
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url("../../assets/images/photo/about-bg-cover.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
	filter: grayscale(40%);
}

.vision-box,
.mission-box {
	position: relative;
	z-index: 0;
	overflow: hidden;
}
.vision-box::after,
.mission-box::after {
	position: absolute;
	content: "";
	top: 0;
	right: -30%;
	width: 60%;
	height: 60%;
	background-color: #ffedd5;
	z-index: -1;
	transform: rotate(30deg);
	border-radius: 8px;
}
.vision-box::before,
.mission-box::before {
	position: absolute;
	content: "";
	bottom: 0;
	left: -30%;
	width: 60%;
	height: 60%;
	background-color: #ffedd5;
	z-index: -1;
	transform: rotate(30deg);
	border-radius: 8px;
}

/* .apply-now-section {
	position: relative;
	z-index: 0;
}

.apply-now-section::before {
	content: "";
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	background-color:#FFECD4;
	width: 50%;
	height: 100%;
	transform: rotateX(45deg);
	-webkit-clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%);
	clip-path: polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%);
} */

.map-pointer {
	clip-path: polygon(49% 42%, 0% 100%, 100% 100%);

}
