.showcase-swiper-slider .swiper-pagination-bullet {
	background: #23262f;
	position: relative;
	bottom: 0;
	left: 0;
}

.fancy-border-radius-box {
	width: auto;
	height: auto;
	z-index: 1;
	/* border-radius:15% 85% 78% 22% / 28% 67% 33% 72% ; */
	clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.cp-process-box-right {

	-webkit-clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
	clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}

.cp-process-arrow-right {
	-webkit-clip-path: polygon(
		75% 0%,
		100% 50%,
		75% 100%,
		0% 100%,
		25% 50%,
		0% 0%
	);
	clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
}
