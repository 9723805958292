/* Form  */
.dashboard-form {
	display: flex;
	flex-wrap: wrap;
}

.dash-input-group {
	border: 0px slateblue dashed;
	width: calc(100% * 1 / 2);
	padding: 2px 16px 14px 16px;
}

.dash-input-group-full {
	width: calc(100% * 1);
	padding: 2px 16px 14px 16px;
}

@media screen and (max-width: 560px) {
	.dash-input-group,
	.dash-input-group-full {
		width: 100%;
	}
}
