.heading-title h3,
.title-sm {
	position: relative;
}

.heading-title h3::before {
	position: absolute;
	content: "";
	width: 2rem;
	height: 0.275rem;
	background-color: #f14b0f;
	top: calc(100% + 2px);
	left: -4%;
	transform: translateX(-8%);
}

.title-sm::before {
	/* position: absolute;
	content: "";
	width: 8%;
	height: 0.275rem;
	background-color: #f14b0f;
	top: 100%;
	left: -2%; */
}

@media screen and (max-width: 768px) {
	/* .title-sm::before {
		width: 14%;
		top: 100%;
		left: 30%;
		transform: translateX(-30%);
	} */
}

/* Team by - styles.css */
.bg-team-cover {
	z-index: 0;
	position: relative;
	overflow: hidden;
}
.bg-team-cover::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.875);
	top: 0;
	left: 0;
	z-index: 1;
}
.bg-team-cover::before {
	z-index: 0;
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url("../../assets/images/photo/our-team-bg-cover3.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	filter: grayscale(100%);
}

.testimonial-swiper-slider .swiper-pagination-bullet,
.team-swiper-slider .swiper-pagination-bullet {
	background: #23262f;
	position: relative;
	bottom: 0;
	left: 0;
}

/* Are you Ready css */

.clip-path-wrap {
	position: relative;
	z-index: 0;
	overflow: hidden;
}

.clip-path-1::before {
	content: "";
	position: absolute;
	z-index: 0;
	top: 4%;
	left: -2%;
	background-color: rgba(245, 121, 121, 0.8);
	width: 40%;
	height: 100%;

	clip-path: polygon(0 0, 0 0, 100% 99%, 0% 100%);
}
.clip-path-1::after {
	content: "";
	position: absolute;
	z-index: 0;
	top: -4%;
	right: -2%;
	background-color: rgba(253, 180, 180, 0.2);
	width: 40%;
	height: 100%;
	clip-path: polygon(0 0, 0 0, 100% 99%, 100% 0);
}

/* .member-card {
	position: relative;
	z-index: 0;
	overflow: hidden;
}

.member-card::after {
	content: "";
	position: absolute;
	z-index: 0;
	bottom: 19%;
	right: 0;
	background-color: rgba(255, 255, 255, 1);
	width: 70%;
	height: 15%;
	clip-path: polygon(0 100%, 100% 100%, 100% 0);

} */

@media screen and (max-width: 1024px) {
	.clip-path-1::before {
		top: 4%;
		left: -8%;
	}
	.clip-path-1::after {
		top: -4%;
		right: -8%;
	}
}

/* .member-card {
	overflow: hidden;
	clip-path: circle(35% at 50% 50%);
}
.member-card:hover {
	clip-path: none;
} */

/* Our story css */

.post-box {
	position: relative;
	z-index: 0;
}

.post-box::before {
	z-index: 0;
	content: "";
	position: absolute;
	top: 8px;
	left: 8px;
	background-color: transparent;
	background-image: linear-gradient(
		to bottom,
		rgba(19, 19, 19, 0),
		transparent
	);
	width: calc(100% - 16px);
	height: 40%;
}
