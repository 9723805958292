/* splash images mask svg styles */

@supports (mask-image: url("../../../assets/images/mask-splash-svg.png")) {
	.splash-img-svg {
		mask-image: url("../../../assets/images/mask-splash-svg.png");
		-webkit-mask-image: url("../../../assets/images/mask-splash-svg.png");

		-webkit-mask-position: center center;
		mask-position: center center;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;

		mask-size: 100% 100%;
		-webkit-mask-size: 100% 100%;

		min-height: 425px;
	}
}

@supports not (mask-image: url("../../../assets/images/mask-splash-svg.png")) {
	.splash-img-svg {
		border-radius: 46% 54% 39% 61% / 53% 19% 81% 47%;
		box-shadow: 0 0 50px 50px white inset;
	}
}

.hero-swiper-section {
	position: relative;
	overflow: hidden;
	z-index: 0;
}

.hero-basic-section {
	position: relative;
	overflow: hidden;
	z-index: 0;
}
.bubble {
	border-radius: 21% 79% 77% 23% / 71% 61% 39% 29%;
}

.hero-basic-section::before {
	position: absolute;
	z-index: -1;
	content: "";
	top: 2%;
	left: -5%;
	background-color: rgba(245, 118, 86, 0.1);
	width: 20%;
	height: 96%;
	border-radius: 21% 79% 77% 23% / 71% 61% 39% 29%;

	display: none;
}

.hero-basic-section::after {
	position: absolute;
	z-index: -1;
	content: "";
	top: 10%;
	left: 20%;
	background-color: rgba(241, 190, 95, 0.1);
	width: 180px;
	height: 180px;
	border-radius: 49% 51% 49% 51% / 50% 35% 65% 50%;
	display: none;
}

.bshero-image-box {
	position: relative;
}

.bshero-image-box img {
	box-shadow: 36px 36px 0px -26px rgba(156, 156, 156, 0.22);
	-webkit-box-shadow: 36px 36px 0px -26px rgba(156, 156, 156, 0.22);
	-moz-box-shadow: 36px 36px 0px -26px rgba(156, 156, 156, 0.22);
}

.bshero-image-box::before {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	filter: grayscale(1);
	filter: blur(0.4rem);
	background-color: rgba(249, 250, 251, 0);
	width: 100%;
	height: 4rem;
	animation: MoveUpDown 3s linear infinite;
}

@keyframes MoveUpDown {
	from {
		top: 0;
		background-color: rgba(249, 250, 251, 0.6);
	}

	50% {
		top: 45%;
	}

	75% {
		top: 90%;
	}

	95% {
		top: 90%;
	}

	to {
		top: 0;
		opacity: 0;
	}
}

/* 
.hero-snap-rounded::before {
	position: absolute;
	top: -5%;
	left: -6%;
	content: "";
	width: 90%;
	height: 90%;
	z-index: -1;
	background-color: #f9edea;
	border: 3px dashed rgb(247, 192, 142);
	border-radius: 0.1rem;
}
.hero-snap-rounded::after {
	position: absolute;
	bottom: -5%;
	right: -6%;
	content: "";
	width: 90%;
	height: 90%;
	z-index: -1;
	background-color: rgb(255, 219, 184);
	border: 3px dashed rgb(138, 133, 160);
	border-radius: 0.1rem;

} */

@media screen and (max-width: 1366px) {
	.hero-basic-section::after {
		top: 15%;
		left: 17%;

		width: 120px;
		height: 120px;
	}
	.hero-basic-section::before {
		content: "";
		top: 12%;
		left: -10%;
		width: 20%;
		height: 90%;
	}
}
@media screen and (max-width: 768px) {
	.hero-basic-section::before {
		display: none;
	}
	/* .hero-snap-rounded::before {
		top: 4%;
		left: -4%;
		content: "";
		width: 90%;
		height: 100%;
	} */
}
/*
.hero-swiper-section::before {
	content: "";
	position: absolute;
	top: 0%;
	left: 0%;
	background-color: #e02e1a;
	width: 35vh;
	height: 100vh;
	transform: rotate(40deg);
	z-index: -1;
	display: none;
}

.hero-swiper-section::after {
	content: "";
	position: absolute;
	top: -15%;
	left: -5%;
	background-color: #eb402d;
	width: 35vh;
	height: 100vh;
	transform: rotate(40deg);
	z-index: -1;
	display: none;
}**/

/* .hero-swiper-section::after {
	z-index: -1;
	content: "";
	position: absolute;
	top: 50%;
	left: 0%;
	background-color: #FEEFEA;
	width: 50%;
	height: 30%;

	clip-path: polygon(50% 100%, 73% 0, 0 77%, 0 0);
	transform: skewX(-20deg)



} */

@media screen and (min-width: 1680.98px) {
	/* .hero-swiper-section::after {
		left: 1%;
	}
	.hero-swiper-section::before{
		left: 5%;
	} */
}

@media screen and (max-width: 768px) {
	.hero-swiper-section::after,
	.hero-swiper-section::before {
		display: none;
	}
}

.swiper-button-next,
.swiper-button-prev {
	display: none !important;
}

.main-hero-swiper {
	position: relative;
	background-color: white;
}

.swiper-nav-group {
	position: absolute;
	bottom: 12%;
	right: 2%;
	transform: translateY(-10%);
	z-index: 2;
}
.swiper-nav-next,
.swiper-nav-prev {
	width: 36px;
	height: 36px;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white !important;
	background-color: #d8dadb !important;
	transition: all 0.3s ease-in-out;
}

.swiper-nav-next:hover,
.swiper-nav-prev:hover {
	color: white !important;
	background-color: #3b444b !important;
}

@media screen and (max-width: 768.98px) {
	.swiper-nav-group {
		top: 40%;
		right: 4%;
	}
}

@media screen and (max-width: 680px) {
	.swiper-nav-group {
		top: 24%;
		right: 4%;
	}
}

.img-snap {
	position: relative;
	/* clip-path: polygon(
		626px 463px,
		765px 236px,
		687px 31px,
		271px 100px,
		70px 10px,
		49px 250px,
		133px 406px,
		374px 462px,
		529px 393px
	); */
	/* clip-path: url("#arbitary-clip-path"); */
	border-radius: 30% 70% 71% 29% / 30% 30% 70% 70%;
	box-shadow: -25px -3px 0px 18px rgba(255, 236, 228, 1);
	-webkit-box-shadow: -25px -3px 0px 18px rgba(255, 236, 228, 1);
	-moz-box-shadow: -25px -3px 0px 18px rgba(255, 236, 228, 1);
}

@media screen and (max-width: 768.98px) {
	.img-snap {
		box-shadow: 2px 10px 0px 2px rgba(255, 231, 218, 0.54);
		-webkit-box-shadow: 2px 10px 0px 2px rgba(255, 231, 218, 0.54);
		-moz-box-shadow: 2px 10px 0px 2px rgba(255, 231, 218, 0.54);
	}
}

/* .swiper-slide-active {
	background-color: rebeccapurple !important;
	display: none !important;
} */

.main-hero-swiper .swiper-slide {
	opacity: 0 !important;
}

.main-hero-swiper .swiper-slide.swiper-slide-visible {
	opacity: 1 !important;
}
