@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	/* --main-font: "Gentium Book Plus", serif; */
	/* --urban-font: "Plus Jakarta Sans", serif; */
	/* --sanspro-font: "Gentium Book Plus", serif; */
	/* --sanspro-font: "Source Sans Pro", sans-serif; */
	--main-font: "Maven Pro", sans-serif;
	--urban-font: "Oxygen", sans-serif;
	--sanspro-font: "Maven Pro", serif;
}

html {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--main-font);

	background: #ffffff;
	overflow-x: hidden;
}

.body-scroll-none {
	overflow: hidden;
	margin-right: 0rem;
	/* transition: all 0.3s; */
}

.ff-sanspro {
	font-family: var(--sanspro-font) !important;
}

.ff-urban {
	font-family: var(--urban-font) !important;
}

.btn {
	@apply rounded border border-transparent
	bg-white px-4 py-2.5 text-base
	font-semibold tracking-normal
	text-arsenic-900 xl:px-5 xl:py-2.5;
}

.btn-fill {
	@apply border-white
	bg-halloween-orange-900 tracking-normal
	text-white hover:bg-halloween-orange-800;
}

.btn-outline {
	@apply border border-halloween-orange-800 bg-transparent tracking-normal
	text-halloween-orange-800 hover:bg-halloween-orange-800 hover:text-halloween-orange-50;
}
.btn-round {
	@apply rounded-full;
}

/* Popup Modal styles */

.modal {
	@apply mt-16 w-[96vw] rounded-xl bg-white md:w-[90vw]  lg:mt-20;
}

.modal--sm {
	@apply lg:w-[370px];
}

.modal--md {
	@apply lg:w-[512px];
}

.modal--lg {
	@apply lg:w-[762px];
}

.modal--xl {
	@apply lg:w-[992px];
}

.input-feild-gray {
	@apply ff-urban w-full rounded
	border-yankees-blue-100 text-base
	tracking-wide focus:border-yankees-blue-300
	focus:outline-none focus:ring-0
	focus:ring-yankees-blue-200 focus:ring-offset-2;
}

.input-feild-dark {
	@apply ff-urban w-full rounded
	border-dark-gunmetal/20 text-base
	tracking-wide placeholder:text-sm
	placeholder:text-slate-400 focus:border-dark-cyan
	focus:outline-none focus:ring-0 focus:ring-cyan-300 focus:ring-offset-2;
}

.input-feild-border-0 {
	@apply ff-urban w-full rounded-sm
	border-none bg-yankees-blue-50
	text-base tracking-wide
	focus:border-none focus:bg-yankees-blue-100
	focus:outline-none focus:ring-0;
}

.input-lbl {
	@apply ff-urban m-1 block text-sm font-semibold capitalize tracking-wider text-dark-gunmetal/60;
}

/* Singin and Signup form styles */

.singin-form-overlay {
	position: relative;
	background-color: #e8e8ec;
	z-index: 0;
}

.form-lbl-text {
	@apply inline-block p-0.5 text-[15px]
	 font-normal capitalize text-yankees-blue-600;
}

.form-input-error-text {
	@apply block px-1 pt-0.5 text-[13px] font-medium tracking-wider text-red-900;
}

/* fule-upload-styles */

.upload-choose-btn {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
	color: #fff;
	cursor: pointer;
	border-radius: 4px;
	font-family: inherit;
	border: none;
	outline: none;
	font-size: 0.924em;
	text-transform: capitalize;
	transition: all 0.4s;
	background-color: orange;
	/* background-color: #8fcac8; */
	letter-spacing: 0.4px;
	box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.4);
}

.upload-choose-btn:disabled {
	display: none;
}

.file-preview {
	/*  */
}

.file-upload-actions {
	display: flex;
	align-items: center;
	margin-top: 0.4rem;
	gap: 0.4rem;
}

.file-upload-actions button {
	flex: 1;
	width: 100%;
	height: 36px;
	cursor: pointer;
	font-family: inherit;
	outline: none;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	transition: all 0.4s;
	letter-spacing: 1px;
	padding: 0px 10px;
	/* background-color: #fdf0e4; */
	background-color: rgba(0, 0, 0, 0.4);
	color: rgb(255, 255, 255);
	border-radius: 0.5rem;
	letter-spacing: 0.4px;
}

.file-upload-actions button:hover {
	/* background-color: orange; */
	background-color: white;
	color: #757575;
}

.file-upload-actions button:disabled {
	opacity: 0.6;
	cursor: not-allowed !important;
	background: #ebebeb !important;
	color: #cecece !important;
}
