.single-portfolio-image {
	z-index: 0;
	position: relative;
}
.single-portfolio-image::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	border: 4px dashed #f3c9a6;
	/* background-color: #e68764; */
	top: 8%;
	left: 8%;
	z-index: -1;
}
.single-portfolio-image.image-left::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	border: 4px dashed #f3c9a6;
	/* background-color: #e68764; */
	top: 8%;
	left: -8%;
	z-index: -1;
}



.portfolio-title {
	display: inline-block;
	z-index: 0;
	position: relative;
}

.portfolio-title::before {
	content: "";
	position: absolute;
	width: 25%;
	height: 5px;
	background-color: #f3c9a6;
	top: 104%;
	left: -10%;
	z-index: -1;
}

@media screen and (max-width: 768px) {
	.single-portfolio-image::before {
		display: none;
	}
}
