.careers-bg-cover {
	position: relative;
	z-index: 0;
}
.careers-bg-cover::before {
	z-index: 0;
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url("../../assets/images/photo/career-bg-cover.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	filter: grayscale(40%);
}

.careers-bg-cover::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(23, 23, 46, 0.6);

	top: 0;
	left: 0;
	z-index: 1;
}

@media screen and (max-width: 768px) {
	.careers-bg-cover::before,
	.careers-bg-cover::after {
		display: block;
	}
}

/* Career Form styles */

.cr-input-group {
}
