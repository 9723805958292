#swiper-1 .swiper-pagination {
	bottom: 0rem;
}

#swiper-1 .swiper-pagination-bullet {
	opacity: 1;
	background-color: rgba(0, 0, 0, 0.75);
	width: 0.75rem;
	height: 0.75rem;
	margin: 0 0.375rem;
}

#swiper-1 .swiper-pagination-bullet-active {
	background-color: rgba(245, 137, 35, 0.75);
}

.clip-title-tags {
	clip-path: polygon(100% 0, 0 49%, 100% 100%);

}
.number-pagination {
	display: block;
	position: absolute;
	bottom: 2rem;
	left: 2rem;
}
